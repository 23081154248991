import HttpUtils from '@/utils/HttpUtils'

// 登录
function login(data) {
	return new Promise((resolve, reject) => {
		HttpUtils.post('/cloud-auth/auth/form', data).then(resolve).catch(reject)
	})
}

// 获取用户信息
function getUserDetail(data) {
	return new Promise((resolve, reject) => {
		HttpUtils.get('/user/user/getUserInfo', data).then(resolve).catch(reject)
	})
}

// 登出
function logout(data) {
	return new Promise((resolve, reject) => {
		HttpUtils.delete('/cloud-auth/token/logout', data)
			.then(resolve)
			.catch(reject)
	})
}

// 获取文件服务器地址
function getFileServer() {
	return new Promise((resolve, reject) => {
		HttpUtils.get('/personnel/upload/picServer.do').then(resolve).catch(reject)
	})
}

// 获取用户信息
function queryPicServer(data) {
	return new Promise((resolve, reject) => {
		HttpUtils.get('/personnel/upload/picServer.do', data)
			.then(resolve)
			.catch(reject)
	})
}

export default {
	login,
	getUserDetail,
	logout,
	getFileServer,
	queryPicServer
}
