import qs from 'qs'
import axios from 'axios'
import store from '@/store'

window.axiosCancel = []

// 自定义axios实例
let instance = axios.create()

// 相对URL
instance.defaults.baseURL = '/api'
// 请求超时时间
instance.defaults.timeout = 60000
// 跨域请求使用凭证
instance.defaults.withCredentials = true
// 身份校验信息
instance.defaults.headers.Authorization = ''
// 在向服务器发送前，统一修改请求数据
instance.defaults.transformRequest = [
	function (data) {
		return qs.stringify(data)
	}
]
// 在传递给 then/catch 前，统一修改响应数据
instance.defaults.transformResponse = [
	function (data) {
		try {
			return JSON.parse(data)
		} catch (e) {
			return data
		}
	}
]
// 请求拦截器
instance.interceptors.request.use(
	(config) => {
		config.headers.Authorization = store.state.authorization

		// 添加取消标记
		config.cancelToken = new axios.CancelToken((cancel) => {
			window.axiosCancel.push({
				cancel
			})
		})

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)
// 响应拦截器
instance.interceptors.response.use(
	(response) => {
		return response.data || {}
	},
	(error) => {
		if (error.response) {
			if (error.response.status == 401) {
				window.axiosCancel.forEach((ele, index) => {
					ele.cancel('')
					delete window.axiosCancel[index]
				})

				window.app.$confirm({
					title: '登录提示',
					content: '您的账号登录已失效，请重新登录！',
					onOk: () => {
						store.dispatch('logout').then(() => {
							window.app.$router.push({ name: 'Login' })
						})
					}
				})
			}
		}
		return {
			code: error.response ? error.response.status : 500,
			msg: error.response ? error.response.data.message : ''
		}
	}
)

export default instance
