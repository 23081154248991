import {
	registerMicroApps,
	addGlobalUncaughtErrorHandler,
	start
} from 'qiankun'

// 子应用注册信息
import apps from './apps'

/**
 * 注册子应用
 * 第一个参数 - 子应用的注册信息
 * 第二个参数 - 全局生命周期钩子
 */
registerMicroApps(apps, {
	// 生命周期钩子 - 加载前
	beforeLoad: () => {
		return Promise.resolve()
	},
	// 生命周期钩子 - 挂载后
	afterMount: () => {
		// 加载子应用前
		return Promise.resolve()
	}
})

/**
 * 添加全局的未捕获异常处理器
 */
addGlobalUncaughtErrorHandler((event) => {
	console.error('应用异常 => ', event)
})

// 导出启动函数
export default start
