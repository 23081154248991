import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/workbench/login'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/smartsite/',
	routes
})

export default router
