import CommonApi from '@/api/CommonApi'

const login = async ({ commit }, data) => {
	let loginResponse, fileResponse

	loginResponse = await await CommonApi.login(data)
	if (loginResponse.code == 200) {
		// Authorization
		commit(
			'SET_AUTHORIZATION',
			loginResponse.data.token_type + ' ' + loginResponse.data.access_token
		)
		loginResponse = await CommonApi.getUserDetail({
			params: {
				id: loginResponse.data.userId
			}
		})
		if (loginResponse.code == 200) {
			// 基本信息
			commit('SET_USERINFO', loginResponse.data)
			// 文件服务器地址
			fileResponse = await CommonApi.getFileServer()
			if (fileResponse.code == 200) {
				commit('SET_FILESERVER', fileResponse.data.pic_server)
			}
		}
	}
	return new Promise((resolve, reject) => {
		if (loginResponse.code == 200) {
			resolve(loginResponse)
		} else {
			reject(loginResponse)
		}
	})
}

const logout = async ({ commit }, data) => {
	let logoutResponse = await CommonApi.logout(data)
	if (logoutResponse.code == 200) {
		commit('INITIAL_STATE')
	}
	return new Promise((resolve, reject) => {
		if (logoutResponse.code == 200) {
			resolve(logoutResponse)
		} else {
			reject(logoutResponse)
		}
	})
}

//图片地址
const QueryPicServer = ({ commit }, data) => {
    return new Promise((resolve) => {
        CommonApi.queryPicServer(data).then((res) => {
            if (res.code === 200) {
                let _data = res.data
                commit('SET_PICSERVER', _data.pic_server)
                resolve()
            }
        })
    })
}

export { login, logout, QueryPicServer }
