import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const state = {
  authorization: '', // 用户身份认证信息
  fileServer: '', // 文件服务器地址
  picServer: '', // 图片访问服务器地址
  userInfo: {
    userId: '', // 用户编号
    tenantOrgId: '', // 所属租户编号
    tenantOrgName: '', // 所属租户名称
    companyId: '', // 所属组织编号
    companyName: '', // 所属组织名称
    departmentId: '', // 所属部门编号
    departmentName: '', // 所属部门名称
    jobId: '', // 所属职业编号
    jobName: '', // 所属职业名称
    userType: '', // 用户类型
    loginName: '', // 用户账号
    name: '', // 真实姓名
    mobile: '', // 手机号码
    photo: '', // 用户头像
    screenShowStatus:0,//显示不显示下拉(0=不显示,1=显示)
  },
  submitLoadding: false, //提交按钮状态
  pType: 1,
  platform: {
    id: '',
    parentIds: '',
    type: '',
    name: ''
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: 'SmartSite_State'
    })
  ]
})
